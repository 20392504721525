@import './main.scss';


.skills-cont {
  @extend .center;
  // border: 2px solid red;
  height: 100vh;
  width: 99vw;
}


.s-title {
  opacity: 0.3;
  margin-bottom: 70px;

  h1 {
    font-size: 9vw;
  }
}


.content{
  @extend .center;
  padding: 0 5vw;

  .skills{
    width: 80vw;

    .s-icon {
      width: 7vw;
      opacity: 0.9;
    }
  }

}
