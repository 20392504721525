@import './main.scss';




.a-cont {
  @extend .flex , .relative;
  flex-direction: row !important;
  padding: 0 3vw; 
}


.img-cont{
  @extend .center, .relative;
  margin: 10vh 4vw;

  .a-img {
    @extend .absolute;
    width: 35vw;
  }

  .a-img1 {
    width: 40vw;
    z-index: -1;
    opacity: 0.1;
  }
}

.title-cont{
  @extend .center, .relative;
  width: 6vw;

  .title{
    font-size: 8vw;
    opacity: 0.2;
    margin-left:-5vw;
    transform: rotate(270deg);
  }
}

.a-desc{
  @extend .center;
  width: 30vw;
  margin: 2vw;
  
  p{
    font-size: 14px;
  }

  .a-line{
  width: 2px;
  height: 5vw;
  margin: 30px 0 ;
  border-right: 1px solid white;
  }
}