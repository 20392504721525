
@import './main.scss';

  .b-section {
    @extend .section , .center;
  }

  
  .circle{
    @extend .center, .absolute;
    margin: 4vh 0;
    width: 2vw;
    height: 2vw;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid white;
  }

  .bg-cont{
    background-attachment: fixed;
    position: absolute;
    @extend .section;
  }
  
    .bg-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: -1;
    }

  .links {
    @extend .flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;

    img {
      width: 1.5vw;
      margin: 10px 0;
    }
  }


  .content {
      position: absolute;
    h2 {
      text-align: center;
      font-size: 2vw;

    }
    h3 {
      text-align: end;
      font-size: 2vw;
    }
    .name {
      font-size: 6vw;
    }
  }

  .arrow{
    cursor: pointer;
  }


  .spinner {
    @extend .absolute;
    width: 95vw;
    height: 20vh;
    opacity: 0.4;
    border-radius: 50%;
    animation: pulse 1000ms infinite; 
    animation-timing-function: ease-in-out; 
    border-bottom : 2px solid rgb(209, 45, 45); 
  }

  .spinner-left {
    @extend .spinner;
    width: 45vw;
    height: 5vh;
    border-top-right-radius: 25px;
    animation: pulse 2500ms infinite;

  }
  
  .other{
    .spinner {
      margin-top: 17%;
    }
    .spinner-left{
      margin-top: 6%;
      margin-right: 50%;
    }
  }
  
  .safari {
    .spinner {
      margin-top: 9%;
    }
    .spinner-left{
      margin: 3% 0 0 3vw;
      left: 0;
    }
  }

  @keyframes pulse {
    0%{
      border-bottom: 1px solid rgb(162, 162, 162);
      transform: skew(27deg);
    }
    50% {
      border-bottom : 1.5px solid rgb(255, 255, 255);  
    }
    100% {
      border-bottom : 2px solid rgb(209, 45, 45);   
      transform: skew(50deg);
    }
  }
