// background-color: #fbfbfb;

 * {
margin: 0;
padding: 0;
color: white;
font-family: 'Overpass', sans-serif;
max-width: 100vw;
// border: 1px solid red;
  }


  .font {
    font-family: 'Bevan', cursive;
    font-size: 100px;
  }
.display-none {
    display: none;
  }

body{
    margin: 0;
  background-color:#1b1b1b;
  }

.relative {
    position: relative;
  }

.section {
  width: 100vw;
  height: 100vh;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex {
  display: flex;
}

.center-text {
  text-align: center;
}

.absolute {
  position: absolute;
}

.side-menu {
  @extend .flex;
  width: 3vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .side-icon {
    width: 3vh;
    margin: 1vw;
    cursor: pointer;
  }
}

.footer {
  text-align: center;
  font-style: italic;
  opacity: 0.2;
  margin: 5vh 0;
}